import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HeroService } from 'src/app/service/hero.service';
import PatternLock from 'patternlock';
import { ToastrService } from 'ngx-toastr'
declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  users: any = {}
  hide = true;
  pattern_lock: any = ''; patterncode: any;
  details: any = {};
  button: any = 'LOG IN'
  isLoading1 = false;

  password;

  show = false;
  constructor(
    private logindata: HeroService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) {


  }

  ngOnInit(): void {
    this.router.navigate(['/login'])
    this.password = 'password';
    this.loginForm = this.formBuilder.group({
      admin_email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$")]],
      admin_password: ['', [Validators.required]],
      admin_pattern: ['fasle', [Validators.required]]
    });
    this.getPattern();

  }
  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
  clearpat() {
    localStorage.removeItem('patt')
  }

  getPattern() {
    var lock = new PatternLock("#patternContainer", {
      onDraw: function (patterncode) {
        var pat = lock.getPattern();
        $("#patterncode").val(pat);
        localStorage.setItem('patt', pat)
        this.patterncode = pat;
        if (this.patterncode.length <= 2) {
          lock.error();
          lock.reset();
        } else {
          this.patterncode = pat;
        }
      }
    });
  }
  patternReset() {
    $("#patterncode").val('');
    var lock = new PatternLock("#patternContainer", {
      onPattern: function (pattern) {
        lock.clear();
        $("#patterncode").val('');
      }
    });
  }
  get l(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  clearcode() {
    let pattVal = $("#patterncode").val();
    if (pattVal) {
      $("#patterncode").val('');
    }
  }
  getAdminData(loginForm: any) {

    this.submitted = true;
    const val = this.loginForm.value;
    $("#patterncode").val(localStorage.getItem('patt'))
    this.pattern_lock = $("#patterncode").val();
    val.admin_pattern = this.pattern_lock
    if (this.loginForm.valid) {
      this.isLoading1 = true;
      this.button = 'Processing';
      this.logindata.postFormData('/adminprofile/adminlogin', { admin_email: val.admin_email, admin_password: val.admin_password, admin_pattern: val.admin_pattern }).subscribe((result: any) => {

        if (result['status']) {
          localStorage.setItem('token', result['token']);
          this.toastr.success(result['message']);
          this.isLoading1 = false;
          this.button = 'LOG IN';
          this.router.navigate(['/dashboard'])
          localStorage.setItem('SubAdminId', result.admin_id)
          // localStorage.setItem('token', result)
          localStorage.removeItem('patt')
          return true;
        } else {
          this.toastr.error(result['message'])
          this.isLoading1 = false;
          this.button = 'LOG IN';
        }
      });
    }
  }

}