import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptographyService {
  constructor() { }
  encrypt(value:any){
    var key = CryptoJS.enc.Utf8.parse('b75524250a7f5id2726a951by99208df');
    var iv = CryptoJS.enc.Utf8.parse('1583280690298111');
    var encrypt = CryptoJS.AES.encrypt(value, key,{iv:iv}).toString()
    return encrypt
  }
  decrypt(value) {
    var key = CryptoJS.enc.Utf8.parse('b75524250a7f5id2726a951by99208df');
    var iv = CryptoJS.enc.Utf8.parse('1583280690298111');
    var decrpted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8)

    return decrpted
  }
}
