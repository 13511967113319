import { Component, OnInit } from '@angular/core';
import { CryptographyService } from 'src/app/service/cryptography.service';
import { HeroService } from 'src/app/service/hero.service';
import { ToasterService } from 'src/app/service/toaster.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  public sellerCollapsed = false;
  public categeryCollapsed = false;
  public ExportCollapsed = false;
  public StockCollapsed = false;
  public AttendanceCollapsed = false;
  public productCollapsed = false;
  public ordersCollapsed = false;
  public productsCollapsed = false;
  public shoppingCollapsed = false;
  public userCollapsed = false;
  public ecommerseCollapsed = false;
  public usersCollapsed = false;
  public TestimonialCollapsed = false;
  public emailCollapsed = false;
  public wholesaleCollapsed = false;
  public SubCollapsed = false

  admin: any = {};
  sub: any[];
  id: any
  Adminid: string;
  admin_name: any;
  
  constructor(private emp: HeroService, private toastr: ToasterService, private crypto: CryptographyService) { }
  
  ngOnInit() {
    this.Adminid = localStorage.getItem('SubAdminId')
    this.id=this.crypto.decrypt(this.Adminid)
    this.getSudAdmin()
    const body = document.querySelector('body');

    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
  toastFunc(status, message) {
    this.toastr.toast(status, message);
  }

  getSudAdmin() {

    this.emp.getUrl('/adminprofile/Adminfindone/' + this.id).subscribe((result: any) => {
      if (result && result.status !== 403 || result.status == true) {
        this.admin = result.data;
        var name = this.crypto.decrypt(this.admin.admin_name)
        this.admin_name = name
        this.sub = result.data.access

      } else if (result.status == 403) {
        this.toastFunc(result.status, result.message)
      } else {
        this.admin = {}
      }
    })
  }
  idExists(value) {
    return this.sub?.includes(value)
  }
}
