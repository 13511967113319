import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseUrl } from 'src/baseurl.js'
import { map, catchError } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class HeroService {
  [x: string]: any;

  redirectUrl = BaseUrl;
  private urlSource = new BehaviorSubject({});
  actUrl = this.urlSource.asObservable;
  private proup = new BehaviorSubject({});
  data = this.proup.asObservable();
  private chngmode = new BehaviorSubject({});
  modech = this.chngmode.asObservable();
  cookieService: any;
  admindata: any;
  userdata: number;

  constructor(private http: HttpClient) { 
  }
  adminlogin(admin_email: string, admin_password: string, admin_pattern: any): Observable<any> {
    return this.http.post(this.redirectUrl + '/adminprofile/adminlogin', { admin_email, admin_password, admin_pattern }).pipe(
      map((data: any) => {
        localStorage.setItem('token', JSON.stringify(data))
        return data;
      }), catchError(error => {
        return error;
      })
    )
  }

  postRequest(uri: any, data: any, token: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(this.redirectUrl + uri, data, { headers: headers })
  }

  postUrl(uri: any, data: any) {
    return this.http.post(this.redirectUrl + uri, data);
  }

  getRequest(uri: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(this.redirectUrl + uri, { headers: headers })
  }

  getUrl(uri: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('responseType', 'arraybuffer/blob/json/text')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(this.redirectUrl + uri, { headers: headers })
  }
  getUrlshopping(uri: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('responseType', 'arraybuffer/blob/json/text')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(this.redirectUrl + uri, { headers: headers })
  }

  postRequestshopping(uri: any, data: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('responseType', 'arraybuffer/blob/json/text')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(this.redirectUrl + uri, data, { headers: headers })
  }

  postUrlshopping(uri: any, data: any) {
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('content-type', 'application/json')
    .set('responseType', 'arraybuffer/blob/json/text')
    .set('authorization', 'Bearer ' + localStorage.getItem('token'));
  return this.http.post(this.redirectUrl + uri, data, { headers: headers })
  }
  postFormsData(uri: any, formData: any) {
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('content-type', 'application/json')
    .set('responseType', 'arraybuffer/blob/json/text')
    .set('authorization', 'Bearer ' + localStorage.getItem('token'));
  return this.http.post(this.redirectUrl + uri, formData, { headers: headers })
  }
  postFormssData(url: any, formData: any) {
    const headers = new HttpHeaders()
    .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(this.redirectUrl + url, formData,{headers: headers});
  }

  postsvalue(uri: any, data: any) {
    const headers = new HttpHeaders()
    .set('authorization', 'Bearer ' + localStorage.getItem("tokenone"));
  return this.http.post(this.redirectUrl + uri, data, { headers: headers })
  }


  postFormData(url: any, formData: any) {
    return this.http.post(this.redirectUrl + url, formData);
  }
  postFormmData(url: any, formData: any,product_id:any) {
    return this.http.post(this.redirectUrl + url, formData,product_id);
  }

 
  putRequest(uri: any, token: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(this.redirectUrl + uri, { headers: headers })
  }

  putUrl(uri: any) {
    return this.http.get(this.redirectUrl + uri);
  }
  deleteRequest(uri: any, token: any) {
    const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(this.redirectUrl + uri, { headers: headers })
  }

  deleteUrl(uri: any) {
    return this.http.get(this.redirectUrl + uri);
  }

}





