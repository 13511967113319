import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { CryptographyService } from 'src/app/service/cryptography.service';
import { HeroService } from 'src/app/service/hero.service';
import { ToasterService } from 'src/app/service/toaster.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  admin:any={}
  id: any;
  sub: any[];


  constructor(config: NgbDropdownConfig , private emp:HeroService,private router:Router, private toastr: ToasterService, private crypto: CryptographyService) {
    config.placement = 'bottom-right';
  }

  ngOnInit(){
    var Adminid = localStorage.getItem('SubAdminId')
    this.id=this.crypto.decrypt(Adminid)
   
    this.emp.getUrl('/adminprofile/Adminfindone/' + this.id).subscribe((result:any)=>{
      if(result &&  result.status != 403){        
        this.admin= result.data;      
      }else if (result.status == 403){
        this.logout()
        this.toastFunc(result.status, result.message)
      }else{
        this.admin ={}
      }
    })
  
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }
  logout(){
    localStorage.clear()
    this.router.navigate(['/login'])
  }
  // logout(){
  //   localStorage.clear()
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('subid');
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 100)
  //   this.router.navigate(['/login']);
  //   // window.location.reload();

  // }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }
  toastFunc(status, message) {
    this.toastr.toast(status, message);
  }
  idExists(value) {
    return this.sub?.includes(value)
  }

}
