<div id="background-wrap">
  <div class="bubble x10"></div>
  <div class="bubble x1"></div>
  <div class="bubble x7"></div>
  <div class="bubble x2"></div>
  <div class="bubble x6"></div>
  <div class="bubble x9"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x10"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x3"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x8"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x7"></div>
</div>
<div class="row" id="proBanner">


  <div class="col-12">
  </div>
</div>
<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-primary text-white mr-2">
      <i class="mdi mdi-home"></i>
    </span>
    Dashboard
  </h3>

  <nav aria-label="breadcrumb">
    <ul class="breadcrumb">
    </ul>
  </nav>
</div>
<div class="row">
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-primary card-img-holder text-white" routerLink="/userlist/usercount"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">User Count
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" id="value" [CountTo]="userlist.usercount" [from]="0"
        [duration]="2" >{{userlist.usercount}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-danger card-img-holder text-white" routerLink="/userlist/activeAccount"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Active Users List
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.activeAccount" [from]="0"
        [duration]="2">{{userlist.activeAccount}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-dark card-img-holder text-white" routerLink="/userlist/inactiveAccount"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">InActive Users List
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5"  [CountTo]="userlist.inactiveAccount" [from]="0"
        [duration]="2">{{userlist.inactiveAccount}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-info card-img-holder text-white" routerLink="/userlist/verifymail"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Verified Users List
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" id="value" [CountTo]="userlist.verifymail" [from]="0"
        [duration]="2">{{userlist.verifymail}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-success card-img-holder text-white" routerLink="/userlist/unverifymail"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Unverified Users List
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.unverifymail" [from]="0"
        [duration]="2">{{userlist.unverifymail}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-primary card-img-holder text-white" routerLink="/userlist/BlockedAccount"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Blocked Users List
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.BlockedAccount" [from]="0"
        [duration]="2">{{userlist.BlockedAccount}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-danger card-img-holder text-white" routerLink="/userlist/productlist"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Product Count
          <i class="mdi mdi-chart-line mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" id="value" [CountTo]="userlist.Productcount" [from]="0"
        [duration]="2">{{userlist.Productcount}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-warning card-img-holder text-white" routerLink="/userlist/activeproducts"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Active Products
          <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Activepro_count" [from]="0"
        [duration]="2">{{userlist.Activepro_count}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-info card-img-holder text-white" routerLink="/userlist/inactiveproducts"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">InActive Products
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Inactivepro_count" [from]="0"
        [duration]="2">{{userlist.Inactivepro_count}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-success card-img-holder text-white" routerLink="/userlist/Pending_Orders"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Pending Orders
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Pending_Orders" [from]="0"
        [duration]="2">{{userlist.Pending_Orders}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-dark card-img-holder text-white" routerLink="/userlist/Packed_Orders"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Packed Orders
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Packed_Orders" [from]="0"
        [duration]="2">{{userlist.Packed_Orders}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-primary card-img-holder text-white" routerLink="/userlist/Shipped_Orders"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Shipped Orders
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Shipped_Orders" [from]="0"
        [duration]="2">{{userlist.Shipped_Orders}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin" style="cursor: pointer;">
    <div class="card bg-gradient-danger card-img-holder text-white" routerLink="/userlist/Delivered_Orders"
      routerLinkActive="active">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Delivered Orders
          <i class="mdi mdi-diamond mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5" [CountTo]="userlist.Delivered_Orders" [from]="0"
        [duration]="2"> {{userlist.Delivered_Orders}}</h2>
        <h6 class="card-text">No.of.Counts</h6>
      </div>
    </div>
  </div>
</div>