import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { HeroService } from './hero.service';
import { ToWords } from 'node_modules/to-words';
import * as CryptoJS from 'crypto-js';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  fontpopping: {
      normal: `${window.location.origin}/assets/font/Poppins-Regular.ttf`,
      bold: `${window.location.origin}/assets/font/Poppins-Bold.ttf`,
      italics: `${window.location.origin}/assets/font/Poppins-Italic.ttf`,
  },
  Headerfont: {
    normal: `${window.location.origin}/assets/font/CarterOne-Regular.ttf`,
    bold: `${window.location.origin}/assets/font/Jost-SemiBold.ttf`,
    italics: `${window.location.origin}/assets/font/Jost-Italic.ttf`,
}
}; 

class Product {
  productname: string='kalai';
  price: number=9876543211;
  qty: number=11;
  discount:any=12;
  sgst:any=11;
  total:any=111;
  
}
class Invoice {
  products: Product[] = [];

  constructor() {
      this.products.push(new Product());     
    }
}
@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  userdata: any;
  total_qty: any;
  total: any;
  words: any;
  siteData: any=[];

  constructor(private toastr: ToastrService, private route: Router,private http:HeroService) { 
    this.getsite()
  }
  toast(status: any, message: any) {
    if (status == true) {
      this.toastr.success(message)
    } else if (status == false) {
      this.toastr.error(message)
    } else if (status == 403) {
      localStorage.clear();
      setTimeout(() => window.location.reload(), 1000);
      this.route.navigate(['/login']);
      this.toastr.warning(message);
    } else if (status == 401) {
      this.toastr.info("please login")
      this.route.navigate(['/login'])
    } else if (status == 'warning') {
      this.toastr.warning(message)
    }
  }


    async generatePdf(data,type){
      console.log(data,'data');
            console.log(data,'userdat');
   
      this.total = data.items.map((p)=>(p.total_amount.toFixed(2)))    
      const toWords = new ToWords();
      let words = toWords.convert(this.total, { currency: true });
      this.words = words
      
              let docDefinition = {
            watermark: {
               text: 
               'Ellina Silks', color: 'dark', fontSize: 100, opacity: 0.1, font: 'Headerfont' },
            content: [
              {
                table: {
                  widths: [40, '*', '*', '*', '*', 30,60],
                  heights: [10,10, 10],
                  body: [
                    [
                      { text: ``,colSpan: 7,border:[true,true,true,false],alignment: 'center', fontSize: 30,margin:[10,20,0,0],font:'Headerfont'},{}, {}, {}, {}, {},{}],        
  
                    [{image: await this.getBase64ImageFromURL("https://res.cloudinary.com/dgj1vdg4l/image/upload/v1677047446/logo-footer_vb76qk.png"),alignment: 'left',border:[true,false,false,false],margin:[0,-90,0,0] },
                    { text: `${this.siteData.contactaddress}`, colSpan: 6,  fontSize: 15,alignment: 'right',border:[false,false,true,false],margin:[0,-20,0,0]}],
                    [{
                      text: `Billing Address:`, colSpan: 3,alignment: 'left',bold:true,border:[true,true,true,false]
                    }, {}, {},
                    {text: `Shipping Address:`, colSpan: 4,alignment: 'right',bold:true,border:[true,true,true,false]
                    }, {}, {},{}
                    ],
                    [{
                      text: ` ${this.siteData.contactaddress}
                    Ph No:${this.siteData.contact_us}`, colSpan: 3,alignment: 'left',border:[true,false,true,false]
                    }, {}, {},
                    {text: `${data.name},
                      ${data.address},
                      ${data.city}, ${data.state},
                      ${data.country}, ${data.pincode},
                     Ph No:${data.phonenumber}`, colSpan: 4,alignment: 'right',border:[true,false,true,false]
                    }, {}, {},{}
                    ],
                    [{ text: '--Order Details--', colSpan: 7, alignment: 'center', bold: true }],
                    [{ text: 'Date: '+ data.items.map((p)=>(p.delivery_date)), colSpan: 3 },{},{},{ text: 'Invoice No: '+data.orderBill_id, colSpan: 4,alignment: 'right'},{},{},{}],
  
                    [{ text: '--Product List--', colSpan: 7, alignment: 'center', bold: true }],
                    [{ text: 'S. No', }, { text: 'Product',colSpan: 2 ,alignment: 'center',}, {}, { text: 'Price(₹)',}, { text: 'Discount(%)', }, { text: 'Qty' },{ text: 'Amount' }],
                    ...data.items.map((p, index) => ([index + 1, {text:p.product_name,colSpan: 2}, {}, {text:p.product_price.toFixed(2)}, p.discount_percentage != '' ? p.discount_percentage : '0', p.product_quantity,{text:(p.product_quantity * (p.offer_product == 'true'  ? p.product_offerprice :p.product_price)).toFixed(2),alignment:'right'}])),
                    [{ text: 'Delivery Charge:', colSpan: 6, alignment: 'right' }, {}, {}, {}, {},{}, {text:`${data.items.map((p)=>(p.delivery_charge.toFixed(2)))}` ,alignment: 'right'}],
                    [{ text: 'Total:', colSpan: 6, alignment: 'right' }, {}, {}, {}, {},{}, {text:`${data.items.map((p)=>(p.total_price.toFixed(2)))}`,alignment: 'right'}],
  
                    [{ text: `Total Amount(In Words): ${ this.words}`, colSpan: 7, alignment: 'left' }],
                    [{
                      text: `Declaration:
                    ${'We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct'}`, colSpan: 7
                    }, {}, {}, {}, {}, {},{}],
                  ]
                }
              }
            ],
            defaultStyle: {
              font: 'fontpopping',
            },
            styles: {
              sectionHeader: {
                bold: true,
                decoration: 'underline',
                fontSize: 14,
                margin: [7, 15, 0, 15],
                border: 'solid',
                fontFamily: 'Poppins',
              },
              showline: {
                decoration: 'underline',
              },
              watermark: {
                fontSize: 25,
                color: 'red',
                opacity: 50,
                angle: 50
              },
              inwords: {
                margin: [0, 10, 0, 0]
              }
            }
          };
          if (type === 'submit') {
            pdfMake.createPdf(docDefinition).download();
          } else if (type === 'print') {
            pdfMake.createPdf(docDefinition).print();
          } else if (type === 'open' || type === 'print') {
            pdfMake.createPdf(docDefinition).open();

          }
    }

    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 10, 65, 205, 83);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = error => {
          reject(error);
        };
        img.src = url;
      });
    }
    getsite(){
      this.http.postUrl('/adminprofile/getsitedetails?admin_id=LptVI4US5BFaEYYtIuCVww==',{siteName:'shivayam'}).subscribe((result: any) => {
        if (result && result.status) {
          this.siteData = result.data[0];
        } else {
          this.siteData = []
        }
      });
  }
  decryptUsingAES256(value) {
    var key = CryptoJS.enc.Utf8.parse('b75524250a7f5id2726a951by99208df');
    var iv  = CryptoJS.enc.Utf8.parse('1583280690298111');
    var decrpted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8)
  
    return decrpted
  }
}
