<nav  class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo 01" ><img src="../../../assets/images/logofinal.png" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini 02" ><img src="../../../assets/images/logofinal.png" alt="logo" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" (click)="toggleSidebar()">
      <span class="mdi mdi-menu"></span>
    </button>
    <div class="container d-flex justify-content-center">
      <div class="row">
      
        <div class="col-md-12">
          <h3 class="animate-charcter">Shopping Admin</h3>
        </div>
 
      </div>
    </div>
    <ul class="navbar-nav navbar-nav-right" >
      <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="profileDropdown" >
          <div class="nav-profile-img" *ngIf="admin.admin_image">
            <img src="{{admin.admin_image}}" alt="image" ngbDropdownToggle>
            <span class="availability-status online"></span>
          </div>
          <div class="nav-profile-img" *ngIf="!admin.admin_image">
            <img src="../../../../assets/images/faces-clipart/pic-1.png" alt="image" ngbDropdownToggle>
            <span class="availability-status online"></span>
          </div>
        </a>
        <div class="dropdown-menu navbar-dropdown" ngbDropdownMenu aria-labelledby="profileDropdown">
          <a class="dropdown-item" href="javascript:;" routerLink="admin_profile">
            <i class="mdi mdi-cached mr-2 text-success"></i>
            Profile
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item"  (click)="logout()">
            <i class="mdi mdi-logout mr-2 text-primary"></i>
            Log Out
          </a>
        </div>
      </li>
      <li class="nav-item nav-settings d-none d-lg-block" *ngIf="( id == 1) enable ">
        <a class="nav-link" routerLink="sitesetting">
          <i class="mdi mdi-wrench"></i>
        </a>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" (click)="toggleOffcanvas()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>