import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AppComponent } from './app.component';
import { NavbarComponent } from './component/shared/navbar/navbar.component';
import { SidebarComponent } from './component/shared/sidebar/sidebar.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './component/shared/spinner/spinner.component';
import { ContentAnimateDirective } from './component/shared/directives/content-animate.directive';
import { HeroService } from './service/hero.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { LoginComponent } from './component/user-pages/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { AuthendicationService } from './service/authendication.service';
// import { AdminProfileComponent } from './component/user-pages/admin-profile/admin-profile.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { YourInterceptor } from './service/interceptor.service';
// export { CKEditorModule } from './ckeditor.module';

// import { NgxEditorModule } from 'ngx-editor';
import { CountToModule } from 'angular-count-to';




@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    LoginComponent

    // AdminProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    DataTablesModule,
    CommonModule,
    CKEditorModule,
    CountToModule,
    // NgxEditorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),


  ],
  providers: [ThemeService, HeroService, AuthendicationService, { provide: HTTP_INTERCEPTORS, useClass: YourInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
