<div class="d-flex align-items-center auth px-0">
  <div class="row w-100 mx-0">
    <div class="col-lg-4 mx-auto">
      <div class="auth-form-light text-left py-5 px-4 px-sm-5">
        <div class="brand-logo">
          <img src="assets/images/logofinal.png" alt="logo">
        </div>
        <h4>Hello! Admin please Login</h4>
        <form class="pt-3" [formGroup]="loginForm" (ngSubmit)="getAdminData(loginForm)">
          <div class="form-group">
            <label class="col-sm-6 form-label" style="padding: 0;">Email</label>
            <input type="email" formControlName="admin_email" name="admin_email" class="form-control form-control-lg"
              id="exampleInputemail1" placeholder="Email" required [ngClass]="{ 'is-invalid': submitted && l.admin_email.errors }">
              <div *ngIf="submitted && l.admin_email.errors" class="invalid-feedback">
                <div *ngIf="l.admin_email.errors.required">Email is required</div>
                <div *ngIf="l.admin_email.errors.pattern">Email is invalid</div>

              </div>
              
          </div>
          <div class="form-group">
            <label class="col-sm-6 form-label" style="padding: 0;">Password</label>
            <input type="password" formControlName="admin_password" name="admin_password"
              class="form-control form-control-lg" id="exampleInputadmin_password1" placeholder="Password" required
              [type]="password"required [ngClass]="{ 'is-invalid': submitted && l.admin_password.errors }">
               <i (click)="onClick()" class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
              <i (click)="onClick()" class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
              <div *ngIf="submitted && l.admin_password.errors" class="invalid-feedback">
                <div *ngIf="l.admin_password.errors.required"> Password is required</div>
                <!-- <div *ngIf="l.admin_password.errors.pattern"> Password is invalid</div> -->
              </div>
              </div>
          <div class="form-group" (click)="clearcode()">
            <div id="patternContainer" class="mx-auto w-100 pattern_box"></div>
            <input type="hidden" formControlName="admin_pattern" name="admin_pattern" id="patterncode"
             required>
             
          </div>
         
          <div class="mt-3 d-flex w-70">
            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn " type="submit"[disabled]="isLoading1"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading1}"></i>{{button}}</button>
       
            <button class="btn btn-block btn-dark btn-lg font-weight-medium auth-form-btn" (click)="patternReset()" (click)="clearpat()"
            type="button">Pattern reset</button>
            
              
          </div>
        </form>
      </div>
    </div>
  </div>
</div>