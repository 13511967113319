// const { Host } = required("@angular/core");ng
var host = document.location.hostname;

if (host == "localhost" || host == "192.168.0.25") {
    // BaseUrl = 'http://192.168.30.178:9000';
    // BaseUrl = 'http://192.168.29.11:9000';
    // BaseUrl = 'http://192.168.30.76:9000';
    // BaseUrl = 'http://192.168.29.14:9000';
    // BaseUrl = 'http://192.168.30.156:9000'
    BaseUrl= 'https://userbackend.rajatextiles.com';
    // BaseUrl = 'http://192.168.29.87:9000'
    // BaseUrl= 'http://localhost:9000'
    // BackUrl= 'http://localhost:9080';


} else {
    BaseUrl = 'https://userbackend.rajatextiles.com';

}

export var BaseUrl;
