import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginComponent } from './component/user-pages/login/login.component';
import { AuthGuard } from './guard/auth.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'admin_profile', loadChildren: () => import('./component/user-pages/admin-profile/admin-profile.module').then(m => m.AdminProfileModule), canActivate: [AuthGuard] },
  { path: 'sitesetting', loadChildren: () => import('./component/settings/sitesetting/sitesetting.module').then(m => m.SitesettingModule) },
  { path: 'category', loadChildren: () => import('./shopping/category/category.module').then(m => m.CategoryModule), canActivate: [AuthGuard] },
  { path: 'userlist/:stat', loadChildren: () => import('./shopping/userlist/userlist.module').then(m => m.UserlistModule), canActivate: [AuthGuard] },
  { path: 'userprofile/:identy_link', loadChildren: () => import('./shopping/userprofile/userprofile.module').then(m => m.UserProfileModule), canActivate: [AuthGuard] },
  { path: 'subcategory', loadChildren: () => import('./shopping/subcategory/subcategory.module').then(m => m.SubcategoryModule), canActivate: [AuthGuard] },
  { path: 'topcategory', loadChildren: () => import('./shopping/topcategory/topcategory.module').then(m => m.TopcategoryModule), canActivate: [AuthGuard] },
  { path: 'addproduct', loadChildren: () => import('./shopping/addproduct/addproduct.module').then(m => m.AddproductModule), canActivate: [AuthGuard] },
  { path: 'brand', loadChildren: () => import('./shopping/brand/brand.module').then(m => m.BrandModule), canActivate: [AuthGuard] },
  { path: 'createproduct', loadChildren: () => import('./shopping/createproduct/createproduct.module').then(m => m.CreateproductModule), canActivate: [AuthGuard] },
  { path: 'productview/:product_name/:siteName', loadChildren: () => import('./shopping/productview/productview.module').then(m => m.ProductviewModule), canActivate: [AuthGuard] },
  { path: 'order-list/:status', loadChildren: () => import('./shopping/order-list/order-list.module').then(m => m.OrderListModule), canActivate: [AuthGuard] },
  { path: 'changeorder/:name', loadChildren: () => import('./shopping/changeorder/changeorder.module').then(m => m.ChangeorderModule), canActivate: [AuthGuard] },
  { path: 'users', loadChildren: () => import('./shopping/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
  { path: 'template', loadChildren: () => import('./shopping/template/template.module').then(m => m.TemplateModule), canActivate: [AuthGuard] },
  { path: 'contact', loadChildren: () => import('./shopping/contact/contact.module').then(m => m.ContactModule), canActivate: [AuthGuard] },
  { path: 'faq', loadChildren: () => import('./shopping/faq/faq.module').then(m => m.FaqModule), canActivate: [AuthGuard] },
  { path: 'subscribe', loadChildren: () => import('./shopping/subscribe/subscribe.module').then(m => m.SubscribeModule), canActivate: [AuthGuard] },
  { path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule), canActivate: [AuthGuard] },
  { path: 'invoice', loadChildren: () => import('./shopping/invoice/invoice.module').then(m => m.InvoiceModule), canActivate: [AuthGuard] },
  { path: 'wholesaletable', loadChildren: () => import('./shopping/wholesaletable/wholesaletable.module').then(m => m.WholesaletableModule), canActivate: [AuthGuard] },
  { path: 'wholesaleorder', loadChildren: () => import('./shopping/wholesaleorder/wholesaleorder.module').then(m => m.WholesaleorderModule), canActivate: [AuthGuard] },
  { path: 'wholesalecustomer', loadChildren: () => import('./shopping/wholesalecustomer/wholesalecustomer.module').then(m => m.WholesalecustomerModule), canActivate: [AuthGuard] },
  {path:'subadminlist',loadChildren:()=>import('./shopping/SubAdmin/sub-admin-list/sub-admin-list.module').then(m=>m.SubAdminListModule)},
  { path: 'SubLogs', loadChildren: () => import('./shopping/SubAdmin/sub-logs/sub-logs.module').then(m => m.SubLogsModule), canActivate: [AuthGuard] },
  { path: 'SubAdd', loadChildren: () => import('./shopping/SubAdmin/sub-add/sub-add.module').then(m => m.SubAddModule), canActivate: [AuthGuard] },
  { path: 'testimonial', loadChildren: () => import('./shopping/testimonial/testimonial.module').then(m => m.TestimonialModule), canActivate: [AuthGuard] },
  { path: 'sellerdocs', loadChildren: () => import('./shopping/sellerdocs/sellerdocs.module').then(m => m.SellerdocsModule), canActivate: [AuthGuard] },
  { path: 'sellone/:email', loadChildren: () => import('./shopping/sellone/sellone.module').then(m => m.SelloneModule) , canActivate: [AuthGuard]},
  { path: 'sellerproduct', loadChildren: () => import('./shopping/sellerproduct/sellerproduct.module').then(m => m.SellerproductModule) , canActivate: [AuthGuard]},
  { path: 'sellerproductone/:email', loadChildren: () => import('./shopping/sellerproductone/sellerproductone.module').then(m => m.SellerproductoneModule), canActivate: [AuthGuard] },
  { path: 'sellerprodetails/:email', loadChildren: () => import('./shopping/sellerprodetails/sellerprodetails.module').then(m => m.SellerprodetailsModule) },
  { path: 'sellerindivpro/:sellerName', loadChildren: () => import('./shopping/sellerindivpro/sellerindivpro.module').then(m => m.SellerindivproModule) }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
