<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile">
      <a class="nav-link">
        <div class="nav-profile-image" *ngIf="admin.admin_image">
          <img src="{{admin.admin_image}}" alt="profile">
          <span class="login-status online"></span>
        </div>
        <div class="nav-profile-image" *ngIf="!admin.admin_image">
          <img src="./../../../../assets/images/faces-clipart/pic-1.png" alt="profile">
          <span class="login-status online"></span>
        </div>
        <div class="nav-profile-text">
          <span class="font-weight-bold mb-2">{{admin.admin_email=='shoppingcart@gmail.com' ? admin_name : admin.admin_name}}</span>
          <span class="text-secondary text-small" *ngIf="admin.admin_image">Admin</span>
        </div>
        <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
      </a>
    </li>
    <li class="nav-item" [ngClass]="{ 'active': dashboard.isActive }" >
      <a class="nav-link" routerLink="/dashboard" routerLinkActive #dashboard="routerLinkActive">
        <span class="menu-title">Dashboard</span>
        <i class="mdi mdi-home menu-icon"></i>
      </a>
    </li>

    <li class="nav-item" *ngIf="(idExists(2) || id == 1) enable ">
      <a class="nav-link" (click)="SubCollapsed = !SubCollapsed" [attr.aria-expanded]="SubCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Sub Admin Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-account-switch"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!SubCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"
            [ngClass]="{'active' :List.isActive  }">
            <a class="nav-link" routerLink="subadminlist" routerLinkActive="active"
              #List="routerLinkActive">
              Sub Admin List</a>
    
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(3) || id == 1) enable ">
      <a class="nav-link" (click)="usersCollapsed = !usersCollapsed" [attr.aria-expanded]="usersCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">User Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-account-search"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!usersCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" [ngClass]="{'active' :userlistmaterial.isActive}">
            <a class="nav-link" routerLink="users" routerLinkActive="active" #userlistmaterial="routerLinkActive">User
              List </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(4) || id == 1) enable ">
      <a class="nav-link" (click)="shoppingCollapsed = !shoppingCollapsed" [attr.aria-expanded]="shoppingCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Category Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-content-duplicate"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!shoppingCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"
            [ngClass]="{'active' :categorymaterial.isActive || category1material.isActive || category2material.isActive || userlistmaterial.isActive   }">
          
            <div class="collapse" id="ui-basic" [ngbCollapse]="!shoppingCollapsed">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item"><a class="nav-link" routerLink="category" routerLinkActive="active"
                    #categorymaterial="routerLinkActive">Main Category</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="topcategory" routerLinkActive="active"
                    #category1material="routerLinkActive">Top Category</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="subcategory" routerLinkActive="active"
                    #category2material="routerLinkActive">Sub Category</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="brand" routerLinkActive="active"
                    #userlistmaterial="routerLinkActive">Brand</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(5) || id == 1) enable ">
      <a class="nav-link" (click)="productCollapsed = !productCollapsed" [attr.aria-expanded]="productCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Product Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-calculator"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!productCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" [ngClass]="{'active' :userlistmaterial.isActive || user1listmaterial.isActive  }">
            <a class="nav-link" routerLink="createproduct" routerLinkActive="active"
              #user1listmaterial="routerLinkActive"> Create Product </a>
            <a class="nav-link" routerLink="addproduct" routerLinkActive="active" #userlistmaterial="routerLinkActive">
              Product List</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(6) || id == 1) enable ">
      <a class="nav-link" (click)="ordersCollapsed = !ordersCollapsed" [attr.aria-expanded]="ordersCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Order Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-cart"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!ordersCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" [ngClass]="{'active' :userlistmaterial.isActive || user1listmaterial.isActive  }">
            <a class="nav-link" routerLink="order-list/ordered" routerLinkActive="active"
              #userlistmaterial="routerLinkActive">
              Pending Orders</a>
            <a class="nav-link" routerLink="order-list/packed" routerLinkActive="active"
              #user1listmaterial="routerLinkActive"> Packed Orders </a>
            <a class="nav-link" routerLink="order-list/shipped" routerLinkActive="active"
              #user1listmaterial="routerLinkActive"> Shipped Orders </a>

            <a class="nav-link" routerLink="order-list/delivered" routerLinkActive="active"
              #user1listmaterial="routerLinkActive"> Delivered Orders </a>
            <a class="nav-link" routerLink="order-list/cancelled" routerLinkActive="active"
              #user1listmaterial="routerLinkActive"> Cancelled Orders </a>
       
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(7) || id == 1) enable ">
      <a class="nav-link" (click)="emailCollapsed = !emailCollapsed" [attr.aria-expanded]="emailCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">User Panel</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-crosshairs-gps"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!emailCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"
            [ngClass]="{'active' :emailmaterial.isActive || conatctmaterial.isActive || cmsmaterial.isActive  }">
            <div class="collapse" id="ui-basic" [ngbCollapse]="!emailCollapsed">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item"><a class="nav-link" routerLink="template" routerLinkActive="active"
                    #emailmaterial="routerLinkActive">Email Template</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="contact" routerLinkActive="active"
                    #conatctmaterial="routerLinkActive">User Contact</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="subscribe" routerLinkActive="active"
                    #conatctmaterial="routerLinkActive">User Subscribe</a></li>
           
                <li class="nav-item"><a class="nav-link" routerLink="cms" routerLinkActive="active"
                    #cmsmaterial="routerLinkActive">CMS </a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" *ngIf="(idExists(8) || id == 1) enable ">
      <a class="nav-link" (click)="wholesaleCollapsed = !wholesaleCollapsed" [attr.aria-expanded]="wholesaleCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">WholeSale</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-laptop-mac"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!wholesaleCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"
            [ngClass]="{'active' :wholesalelistmaterial.isActive || wholesale1listmaterial.isActive  }">
            <a class="nav-link" routerLink="wholesalecustomer" routerLinkActive="active"
              #wholesalelistmaterial="routerLinkActive">
              Customers & Order</a>
            <a class="nav-link" routerLink="wholesaleorder" routerLinkActive="active"
              #wholesale1listmaterial="routerLinkActive"> Overall Orders </a>
          </li>
        </ul>
      </div>
    </li>
    
    <li class="nav-item" *ngIf="(idExists(11) || id == 1) enable ">
      <a class="nav-link" (click)="sellerCollapsed = !sellerCollapsed" [attr.aria-expanded]="sellerCollapsed"
        aria-controls="ui-basic">
        <span class="menu-title">Seller Management</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-account-multiple-plus"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!sellerCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" [ngClass]="{'active' :userlistmaterial.isActive}">
            <a class="nav-link" routerLink="sellerdocs" routerLinkActive="active" #userlistmaterial="routerLinkActive">Seller Details</a>
          </li>
          <li class="nav-item" [ngClass]="{'active' :userlistmaterial.isActive}">
            <a class="nav-link" routerLink="sellerproduct" routerLinkActive="active" #userlistmaterial="routerLinkActive">Seller Products</a>
          </li>
        </ul>
      </div>
    </li>

  </ul>
</nav>